.card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
.facilities-container {
  padding: 2rem;
  color: #333;
}

.facilities-header {
  text-align: left;
  margin-bottom: 2rem;
}

.facilities-header h2 {
  font-size: 2rem;
  color: #4b4bfb;
}

.facilities-header p {
  font-size: 1rem;
  color: #6f6f6f;
  line-height: 1.5;
}

.facilities-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.facility-card {
  background-color: rgba(75, 75, 251, 0.5); /* Warna biru dengan 50% transparansi */
  color: white;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.facility-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  background-color: rgba(75, 75, 251, 1); /* Latar belakang solid saat hover */
}

.facility-card .icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.facility-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.facility-card p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.facility-card a {
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.facility-card a:hover {
  text-decoration: underline;
}

