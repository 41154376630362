/* CSS untuk memastikan responsivitas di berbagai ukuran layar */

/* Untuk layar di atas 1025px (desain desktop besar) */
@media (min-width: 1025px) {
    .portfolio_section .portfolio_carousel .swiper-slide {
        padding: 0 20px;  /* Menambahkan padding antar slide pada layar besar */
    }
}

/* Untuk layar antara 768px - 1024px (tablet) */
@media (max-width: 1024px) and (min-width: 768px) {
    .portfolio_section .portfolio_carousel .swiper-slide {
        padding: 0 15px;  /* Mengurangi padding antar slide */
    }
    .portfolio_block {
        margin-bottom: 20px;  /* Memberikan jarak antar blok portfolio */
    }
}

/* Untuk layar lebih kecil dari 768px (mobile) */
@media (max-width: 768px) {
    .portfolio_section .portfolio_carousel .swiper-slide {
        padding: 0 10px;  /* Mengurangi padding pada mobile */
    }

    .portfolio_block {
        margin-bottom: 15px;  /* Mengurangi margin bawah untuk lebih rapat */
    }
}

/* Mengatur gambar agar responsif */
.portfolio_image img {
    width: 100%;
    height: auto;  /* Membuat gambar tetap responsif */
}

/* Pastikan teks dan tombol tetap memiliki jarak yang cukup di perangkat kecil */
.portfolio_content {
    padding: 10px;
}

h2.heading_text {
    font-size: 2rem;  /* Mengurangi ukuran font untuk responsivitas */
}

.heading_description {
    font-size: 1rem;
}
